import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import classNames from "classnames";
import CButton from "./button";
import { Redux, WebClient, constants, UpDown } from "../../../../components";
import moment from "moment";
import Swal from "sweetalert2/src/sweetalert2.js";

let layout = {
  default: ["1 2 3", "4 5 6", "7 8 9", "{clear} 0 {bksp}"],
};

let buttonTheme = [
  {
    class: "keyboard-close",
    buttons: "{close}",
  },
  {
    class: "keyboard-clear",
    buttons: "{clear}",
  },
  {
    class: "keyboard-search",
    buttons: "{search}",
  },
  {
    class: "keyboard-caps",
    buttons: "{space} {lock} {shift} {bksp}",
  },
];

class TicketCheck extends React.Component {
  static defaultProps = {
    barcode: "",
  };

  state = {
    input: "",
    tab: 0,
    checkedTab: 0,
    checked: false,
    ticket: null,
    ticketContent: [],
    sellType: null,
    bingoData: null,
    cardInfo: [],
    gameDetail: null,
  };
  componentDidMount() {
    this._initBarcodeListener();
    // this.setState({input: this.props.keyword})
    if (this.props?.barcode?.length > 0) {
      this.setState({ input: this.props.barcode }, () => {
        this._check();
      });
    }
  }

  _initBarcodeListener() {
    let barcode = "";
    let interval;
    document.addEventListener("keydown", (event) => {
      // console.log("keydown", event.code)

      if (interval) clearInterval(interval);
      if (event.code == "Enter") {
        if (barcode.length > 0) {
          this._handleBarcode(
            barcode.replace(/\*/g, "").replace(/\(/g, "").replace(/\)/g, "")
          );
          barcode = "";
          return;
        }
      }

      if (
        event.code != "Shift" &&
        event.code != "ShiftLeft" &&
        event.code != "ShiftRight"
      ) {
        barcode += event.key;
      }

      interval = setInterval(() => {
        barcode = "";
      }, 20);
    });
  }

  _handleBarcode(b) {
    this.setState({ input: b }, () => {
      this._check();
    });
  }

  onChange = (input) => {
    this.setState({ input });
    // console.log(input)
    // this.props.onChange(input)
  };

  onKeyPress = (button) => {
    // console.log("Button pressed", button);
    if (button === "{shift}" || button === "{lock}") this.handleShift();
    if (button === "{close}") {
      this.setState({ input: "" }, () => {
        this.props.onClose();
      });
    }
    if (button === "{search}") {
      if (this.state.input?.length > 3) {
        this.props.onSearch();
      }
    }
    if (button === "{clear}") {
      this.keyboard.clearInput();
      this.setState({ input: "" });
    }
  };

  handleShift() {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle,
    });
  }

  _check() {
    if (this.state.input.length > 2) {
      const wc = new WebClient();
      if (this.state.tab == 0) {
        wc.post(
          constants.uris.ttic,
          {
            code: this.state.input,
          },
          {
            setLang: true,
            setUserId: true,
          }
        ).then((response) => {
          // console.log(response)
          if (response.status) {
            this.setState({
              checked: true,
              ticket: response.ticket,
              ticketContent: response.ticketContent,
              sellType: response.sellType,
              checkedTab: 0,
            });
          } else {
            Redux.showToast("error", response.msg);
          }
        });
      } else if (this.state.tab == 1) {
        wc.post(
          constants.uris.ttbc,
          {
            code: this.state.input,
          },
          {
            setLang: true,
            setUserId: true,
          }
        ).then((response) => {
          // console.log(response)
          if (response.status) {
            this.setState({
              checked: true,
              cardInfo: response.cardInfo,
              bingoData: response.bingoData,
              gameDetail: response.gameDetail, // Burayı ekleyin
              checkedTab: 1,
            });
          } else {
            Redux.showToast("error", response.msg);
          }
        });
      }
    }
  }

  _cancelTicket() {
    Swal.fire({
      title: this.props.intl.formatMessage({
        id: "lang_cancel_ticket",
        defaultMessage: "lang_cancel_ticket",
      }),
      text: this.props.intl.formatMessage({
        id: "lang_are_you_sure",
        defaultMessage: "lang_are_you_sure",
      }),
      icon: "warning",
      confirmButtonText: this.props.intl.formatMessage({
        id: "lang_approve",
        defaultMessage: "lang_approve",
      }),
      denyButtonText: this.props.intl.formatMessage({
        id: "lang_cancel",
        defaultMessage: "lang_cancel",
      }),
      showDenyButton: true,
    }).then((response) => {
      // console.log(response)
      if (response.isConfirmed) {
        this._approveCancel();
      }
    });
  }

  _approveCancel() {
    const wc = new WebClient();
    wc.post(
      constants.uris.copc,
      {
        ticketId: this.state.ticket.id,
      },
      {
        setLang: true,
        setUserId: true,
      }
    ).then((response) => {
      // console.log(response)
      if (response.status) {
        Redux.showToast("success", response.msg);
        this._sendCancelPrint(this.state.ticket.id);
        this._check();
      } else {
        Redux.showToast("error", response.msg);
      }
    });
  }

  _sendCancelPrint(id) {
    if (window?.electronAPI?.ipcRenderer) {
      let url = constants.prints.cancelprint
        .replace("{{couponId}}", id)
        .replace("{{lang}}", this.props.language);

      /* PRINT COUPON */

      console.log("printUrl", {
        url,
        filename: `ticket_coupon_${id}`,
        type: "ticket",
        couponId: id,
      });
      /* PRINT COUPON */
    }
  }
  async _sendDirectPrint(id) {
    let url = constants.prints.winprint
      .replace("{{couponId}}", id)
      .replace("{{lang}}", "en");

    console.log("geldim", url);

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // console.log("Response data -->", data);
      if (data.status) {
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }

    // PRINT COUPON
    // console.log("printUrl", {
    //     url,
    //     filename: `ticket_coupon_${id}`,
    //     type: 'ticket',
    //     couponId: id
    // })
    // PRINT COUPON
  }
  //bingowinprint
  async _sendDirectPrintbingo(bingoData, lang) {
    const wc = new WebClient();
    
    try {
      const response = await wc.post(
        constants.prints.bingowinprint,
        {
          bingoData: bingoData,
          lang: lang
        },
        {
          setLang: true,
          setUserId: true,
          setReqId: true
        }
      );
  
      if (response && response.status) {
        return response;
      }
      return null;
      
    } catch (error) {
      console.error("Print error:", error);
      throw error;
    }
  }
  _sendSellPrint(id) {
    if (window?.electronAPI?.ipcRenderer) {
      let url = constants.prints.winprint
        .replace("{{couponId}}", id)
        .replace("{{lang}}", this.props.language);

      /* PRINT COUPON */
      console.log("printUrl", {
        url,
        filename: `ticket_coupon_${id}`,
        type: "ticket",
        couponId: id,
      });
      /* PRINT COUPON */
    }
  }

  _directPrint() {
    Swal.fire({
      title: this.props.intl.formatMessage({
        id: "lang_pay_ticket",
        defaultMessage: "lang_pay_ticket",
      }),
      text: this.props.intl.formatMessage({
        id: "lang_are_you_sure",
        defaultMessage: "lang_are_you_sure",
      }),
      icon: "warning",
      confirmButtonText: this.props.intl.formatMessage({
        id: "lang_approve",
        defaultMessage: "lang_approve",
      }),
      denyButtonText: this.props.intl.formatMessage({
        id: "lang_cancel",
        defaultMessage: "lang_cancel",
      }),
      showDenyButton: true,
    }).then((response) => {
      // console.log(response)
      if (response.isConfirmed) {
        this._sendDirectPrint(this.state.ticket.id);
      }
    });
  }

  _addToMyCredit() {
    Swal.fire({
      title: this.props.intl.formatMessage({
        id: "lang_add_to_my_credit",
        defaultMessage: "lang_add_to_my_credit",
      }),
      text: this.props.intl.formatMessage({
        id: "lang_are_you_sure",
        defaultMessage: "lang_are_you_sure",
      }),
      icon: "warning",
      confirmButtonText: this.props.intl.formatMessage({
        id: "lang_approve",
        defaultMessage: "lang_approve",
      }),
      denyButtonText: this.props.intl.formatMessage({
        id: "lang_cancel",
        defaultMessage: "lang_cancel",
      }),
      showDenyButton: true,
    }).then((response) => {
      // console.log(response)
      if (response.isConfirmed) {
        this._addToMyCreditApprove();
      }
    });
  }

  _addToMyCreditApprove() {
    const wc = new WebClient();
    wc.post(
      constants.uris.wtcd,
      {
        couponId: this.state.ticket.id,
      },
      {
        setLang: true,
        setUserId: true,
        setReqId: true,
      }
    )
      .then((response) => {
        const { member } = this.props;

        let a = (
          parseFloat(member.userCredit) + parseFloat(this.state.ticket.income)
        ).toFixed(2);

        Redux.updateUserCredit({
          userCredit: a,
        });

        Swal.fire({
          title: this.props.intl.formatMessage({
            id: "lang_success",
            defaultMessage: "lang_success",
          }),
          text: this.props.intl.formatMessage({
            id: "lang_credit_successfully_added_to_your_account",
            values: { income: this.state.ticket.income },
            defaultMessage: "lang_credit_successfully_added_to_your_account",
          }),
          icon: "success",
          timer: 1500,
          showCancelButton: false,
          showConfirmButton: false,
        });

        this._check();
      })
      .catch((er) => {});
  }

  _sellTicket() {
    Swal.fire({
      title: this.props.intl.formatMessage({
        id: "lang_sell_ticket",
        defaultMessage: "lang_sell_ticket",
      }),
      text: this.props.intl.formatMessage({
        id: "lang_are_you_sure",
        defaultMessage: "lang_are_you_sure",
      }),
      icon: "warning",
      confirmButtonText: this.props.intl.formatMessage({
        id: "lang_approve",
        defaultMessage: "lang_approve",
      }),
      denyButtonText: this.props.intl.formatMessage({
        id: "lang_cancel",
        defaultMessage: "lang_cancel",
      }),
      showDenyButton: true,
    }).then((response) => {
      // console.log(response)
      if (response.isConfirmed) {
        this._approveSell();
      }
    });
  }

  _approveSell() {
    const wc = new WebClient();
    wc.post(
      constants.uris.seco,
      {
        ticketId: this.state.ticket.id,
        sellok: "1",
        siteType: "2",
      },
      {
        setLang: true,
        setUserId: true,
      }
    ).then((response) => {
      // console.log(response)
      if (response.status) {
        this._sendDirectPrint(this.state.ticket.id);
        Redux.showToast("success", response.msg);

        this._check();
      } else {
        Redux.showToast("error", response.msg);
      }
    });
  }

  _getStatusClass(c) {
    switch (c) {
      case "0":
        return "lang_opened";
      case "1":
        return "lang_win";
      case "-1":
        return "lang_lose";
      case "-2":
        return "lang_canceled";
      case "2":
        return "lang_win";
      default:
        return "lang_opened";
    }
  }

  _getStatusText(c) {
    switch (c) {
      case "0":
        return "lang_open";
      case "1":
        return "lang_win";
      case "-1":
        return "lang_lose";
      case "-2":
        return "lang_canceled";
      case "2":
        return "lang_selled";
      default:
        return "lang_opened";
    }
  }

  formatOutComeName(outComeName, special) {
    // Remove all curly braces and replace competitor references
    let formattedName = outComeName
      .replace(/\{competitor1\}/g, "1")
      .replace(/\{competitor2\}/g, "2")
      .replace(/\{(\d+)\}/g, "$1") // This replaces {1}, {2}, etc. with 1, 2, etc.
      .replace(/\$competitor1/g, "1")
      .replace(/\$competitor2/g, "2")
      .replace(/[{}]/g, ""); // This removes any remaining curly braces

    // Add special if it's not "**"
    // if (special !== "**") {
    //     formattedName += ` (${special})`;
    // }

    return formattedName.trim(); // Trim any leading or trailing whitespace
  }
  outcomeDescription(gelenyazi) {
    if (gelenyazi) {
      const pattern1 = "{$competitor1}";
      const pattern2 = "{$competitor2}";
      const pattern3 = "({hcp})";
      const pattern4 = "beraberlik";
      const pattern5 = "berabere";
      const pattern8 = "Gol olmaz";
      const pattern6 = "({+hcp})";

      const pattern7 = "({-hcp})";
      const pattern9 = "hcp=";
      const pattern11 = "goalnr=";
      const pattern12 = "total=";
      const pattern13 = "{total}";
      const pattern88 = "unentschieden";

      const pattern89 = "1 oder X";
      const pattern90 = "1 oder 2";
      const pattern91 = "X oder 2";
      const pattern92 = "score=";
      const pattern93 = "keines";
      const pattern95 = "1 veya 2";
      const pattern94 = "1 veya X";
      const pattern96 = "X veya 2";
      const pattern97 = "draw";
      if (gelenyazi.includes(pattern1)) {
        gelenyazi = gelenyazi.replace(pattern1, "1");
      }

      if (gelenyazi.includes(pattern2)) {
        gelenyazi = gelenyazi.replace(pattern2, "2");
      }

      if (gelenyazi.includes(pattern3)) {
        gelenyazi = gelenyazi.replace(pattern3, "");
      }
      if (gelenyazi.includes(pattern4)) {
        gelenyazi = gelenyazi.replace(pattern4, "X");
      }
      if (gelenyazi.includes(pattern97)) {
        gelenyazi = gelenyazi.replace(pattern97, "X");
      }
      if (gelenyazi.includes(pattern5)) {
        gelenyazi = gelenyazi.replace(pattern5, "X");
      }

      if (gelenyazi.includes(pattern6)) {
        gelenyazi = gelenyazi.replace(pattern6, "+");
      }

      if (gelenyazi.includes(pattern7)) {
        gelenyazi = gelenyazi.replace(pattern7, "-");
      }
      if (gelenyazi.includes(pattern8)) {
        gelenyazi = gelenyazi.replace(pattern8, "-");
      }
      if (gelenyazi.includes(pattern9)) {
        gelenyazi = gelenyazi.replace(pattern9, "");
      }
      if (gelenyazi.includes(pattern11)) {
        gelenyazi = gelenyazi.replace(pattern11, "");
      }
      if (gelenyazi.includes(pattern12)) {
        gelenyazi = gelenyazi.replace(pattern12, "");
      }
      if (gelenyazi.includes(pattern13)) {
        gelenyazi = gelenyazi.replace(pattern13, "");
      }
      if (gelenyazi.includes(pattern88)) {
        gelenyazi = gelenyazi.replace(pattern88, "X");
      }

      if (gelenyazi.includes(pattern89)) {
        gelenyazi = gelenyazi.replace(pattern89, "1/X");
      }
      if (gelenyazi.includes(pattern90)) {
        gelenyazi = gelenyazi.replace(pattern90, "1/2");
      }
      if (gelenyazi.includes(pattern91)) {
        gelenyazi = gelenyazi.replace(pattern91, "X/2");
      }
      if (gelenyazi.includes(pattern92)) {
        gelenyazi = gelenyazi.replace(pattern92, "");
      }
      if (gelenyazi.includes(pattern93)) {
        gelenyazi = gelenyazi.replace(pattern93, "X");
      }
      if (gelenyazi.includes(pattern94)) {
        gelenyazi = gelenyazi.replace(pattern94, "1/X");
      }
      if (gelenyazi.includes(pattern95)) {
        gelenyazi = gelenyazi.replace(pattern95, "1/2");
      }
      if (gelenyazi.includes(pattern96)) {
        gelenyazi = gelenyazi.replace(pattern96, "X/2");
      }
      return gelenyazi;
    } else {
      return "";
    }
  }
  render() {
    const {
      input,
      tab,
      checked,
      sellType,
      ticketContent,
      ticket,
      bingoData,
      cardInfo,
      checkedTab,
      gameDetail,
    } = this.state;
    const { intl, barcode, printedTickets } = this.props;

    return (
      <div className="search-overlay">
        <div
          className={classNames({
            [`w-1/3`]: !checked,
            "w-1/4": checked,
            absolute: checked,
            "left-m": checked,
            "top-m": checked,
          })}
        >
          <div className="w-full ticket-check-tab relative">
            <div
              onClick={() => {
                this.setState({ tab: 0 });
              }}
              className={classNames({
                [`ticket-check-tab-item`]: true,
                selected: Boolean(tab == 0),
              })}
            >
              <FormattedMessage
                id="lang_sport_bets"
                defaultMessage="lang_sport_bets"
              />
            </div>

            <div
              onClick={() => {
                this.setState({ tab: 1 });
              }}
              className={classNames({
                [`ticket-check-tab-item`]: true,
                selected: Boolean(tab == 1),
              })}
            >
              <FormattedMessage
                id="lang_live_bingo"
                defaultMessage="lang_live_bingo"
              />
            </div>
          </div>

          <div className="w-full">
            <div className="search-overlay-input uppercase">
              <FormattedMessage id="lang_pin" defaultMessage="lang_pin" /> :{" "}
              {input}
            </div>
          </div>

          <div className="w-full">
            <Keyboard
              keyboardRef={(ref) => (this.keyboard = ref)}
              layout={layout}
              buttonTheme={buttonTheme}
              display={{
                "{search}": intl.formatMessage({ id: "search" }),
                "{space}": intl.formatMessage({ id: "Space" }),
                "{close}": intl.formatMessage({ id: "close" }),
                "{bksp}": intl.formatMessage({ id: "<" }),
                "{lock}": intl.formatMessage({ id: "CAPS" }),
                "{shift}": intl.formatMessage({ id: "SHIFT" }),
                "{clear}": intl.formatMessage({ id: "Clear" }),
              }}
              onChange={this.onChange}
              onKeyPress={this.onKeyPress}
            />
          </div>

          <div className="w-full h-common mt-2">
            <CButton
              uppercase="uppercase"
              translate={true}
              width="w-full"
              text="lang_approve"
              active={false}
              onClick={() => {
                this._check();
              }}
            />
          </div>

          <div className="w-full h-common mt-2">
            <button
              onClick={() => {
                this.props.onClose();
              }}
              className="bg-red-500 text-white p-2 hover:bg-red-700 uppercase w-full"
            >
              <i className="p-2" />
              <FormattedMessage id="lang_close" defaultMessage="lang_close" />
            </button>
            {/* <CButton uppercase="uppercase"   translate={true} width="w-full" text="lang_close" active={false} onClick={() => { this.props.onClose() }} /> */}
          </div>
        </div>

        {checked && (
          <div className="ticket-result-cont">
            <div className="ticket-result-title">
              <FormattedMessage
                id={checkedTab == 0 ? "lang_sport_bets" : "lang_live_bingo"}
                defaultMessage={
                  checkedTab == 0 ? "lang_sport_bets" : "lang_live_bingo"
                }
              />
            </div>
            <div className="ticket-results">
              {checkedTab == 0 && (
                <>
                  {sellType?.status && (
                    <div className="sell-coupon-cont">
                      <div
                        onClick={() => {
                          this._sellTicket();
                        }}
                      >
                        <FormattedMessage
                          id="lang_sell_coupon"
                          defaultMessage="lang_sell_coupon"
                        />
                      </div>
                      <span>{sellType?.msg}</span>
                    </div>
                  )}

                  {ticket?.status == "-2" && (
                    <div className="sell-coupon-cont">
                      <div>
                        <FormattedMessage
                          id="lang_this_coupon_was_canceled_on"
                          defaultMessage="lang_this_coupon_was_canceled_on"
                          values={{
                            date: moment(ticket.payDate).format(
                              "DD.MM.YYYY HH:mm:ss"
                            ),
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {ticket?.status == "1" && ticket?.paid == 1 && (
                    <div className="sell-coupon-cont">
                      <div>
                        <FormattedMessage
                          id="lang_this_coupon_was_paid_on"
                          defaultMessage="lang_this_coupon_was_paid_on"
                          values={{
                            date: moment(ticket.payDate).format(
                              "DD.MM.YYYY HH:mm:ss"
                            ),
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {ticket?.status == "2" && (
                    <div className="sell-coupon-cont">
                      <div>
                        <FormattedMessage
                          id="lang_this_coupon_was_selled_on"
                          defaultMessage="lang_this_coupon_was_selled_on"
                          values={{
                            date: moment(ticket.payDate).format(
                              "DD.MM.YYYY HH:mm:ss"
                            ),
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <table className="ticket-result-table">
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage
                            id="lang_coupon_no"
                            defaultMessage="lang_coupon_no"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_total_stake"
                            defaultMessage="lang_total_stake"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_coupon_date"
                            defaultMessage="lang_coupon_date"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_type"
                            defaultMessage="lang_type"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_possible_gain"
                            defaultMessage="lang_possible_gain"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_bonus"
                            defaultMessage="lang_bonus"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_total"
                            defaultMessage="lang_total"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_coupon_number"
                            defaultMessage="lang_coupon_number"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_payout"
                            defaultMessage="lang_payout"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_odds"
                            defaultMessage="lang_odds"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_status"
                            defaultMessage="lang_status"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{ticket?.ticketId}</td>
                        <td>{ticket?.totalStake}</td>
                        <td>
                          {moment(ticket?.ticketDate).format(
                            "DD.MM.YYYY HH:mm"
                          )}
                        </td>
                        <td>
                          {ticket?.ticketType} ({ticket?.totalTickets})
                        </td>
                        <td>{ticket?.maxPay}</td>
                        <td>{ticket?.bonusPay}</td>
                        <td>{ticket?.maxPay}</td>
                        <td>{ticket?.totalTickets}</td>
                        <td>{ticket?.income}</td>
                        <td>{ticket?.totalTipps}</td>
                        <td className={this._getStatusClass(ticket?.status)}>
                          <FormattedMessage
                            id={this._getStatusText(ticket?.status)}
                            defaultMessage={this._getStatusText(ticket?.status)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="ticket-result-table">
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage
                            id="lang_type"
                            defaultMessage="lang_type"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_banko"
                            defaultMessage="lang_banko"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_date"
                            defaultMessage="lang_date"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_teams"
                            defaultMessage="lang_teams"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_bet"
                            defaultMessage="lang_bet"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_prediction"
                            defaultMessage="lang_prediction"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_rate"
                            defaultMessage="lang_rate"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_info"
                            defaultMessage="lang_info"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_status"
                            defaultMessage="lang_status"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="lang_result"
                            defaultMessage="lang_result"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticketContent?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.isLive == 0 ? "S" : "L"}</td>
                            <td>{item.isBank == 0 ? "B" : ""}</td>
                            <td>
                              {moment(item.matchDate).format(
                                "DD.MM.YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {item.home} : {item.away}
                            </td>
                            <td>{this.outcomeDescription(item.typeName)}</td>
                            <td>
                              {this.formatOutComeName(
                                item.outComeName,
                                item.special
                              )}
                            </td>
                            <td>{item.odds}</td>
                            <td>{item.oddsInfo}</td>
                            <td className={this._getStatusClass(item.status)}>
                              <FormattedMessage
                                id={this._getStatusText(item.status)}
                                defaultMessage={this._getStatusText(
                                  item.status
                                )}
                              />
                            </td>
                            <td>{item.Results}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {ticket.status == 0 &&
                    ticket?.canceled == 1 &&
                    ticket?.paid == 0 && (
                      <div className="cancel-coupon-cont">
                        <div
                          onClick={() => {
                            this._cancelTicket();
                          }}
                        >
                          <FormattedMessage
                            id="lang_cancel_ticket"
                            defaultMessage="lang_cancel_ticket"
                          />
                        </div>
                      </div>
                    )}

                  {ticket?.status == "1" && ticket?.paid == 0 && (
                    <div className="pay-coupon-cont">
                      {!printedTickets.find(
                        (x) => x.tid == this.state.ticket.id
                      ) && (
                        <>
                          <div
                            onClick={() => {
                              this._addToMyCredit();
                            }}
                          >
                            <FormattedMessage
                              id="lang_add_to_my_credit"
                              defaultMessage="lang_add_to_my_credit"
                            />
                          </div>

                          <div
                            onClick={() => {
                              this._directPrint();
                            }}
                          >
                            <FormattedMessage
                              id="lang_direct_print"
                              defaultMessage="lang_direct_print"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}


{checkedTab == 1 && (
  <>
    <div className="w-full flex flex-wrap">
      {cardInfo?.map((card, index) => (
        <div
          key={index}
          className="bingo-ticket mb-4 mr-4"
          style={{ backgroundColor: "#ccc" }}
        >
          {/* Kart Bilgi Başlığı */}
          <div
            className="bingo-ticket-header"
            style={{
              backgroundColor:
                gameDetail?.gameStatus === "finished"
                  ? card.win_amount === 0
                    ? "#ef4444"
                    : "#22c55e"
                  : "#eab308",
              color: "white",
            }}
          >
            <div className="bingo-ticket-info">
              <span>Karte #{card.cardId}</span>
              <span>Einsatz: {card.bet_amount}</span>
              <span>Gewinn: {card.win_amount}</span>
            </div>
          </div>
          {/* Bingo Numaraları */}
          {card?.numbers?.split(",")?.map((item, index) => {
            return (
              <div key={index} className="bingo-ticket-number">
                <div
                  className={classNames({
                    "bingo-ticket-number-in": Boolean(
                      item !== "0" && item !== "#"
                    ),
                    "bingo-ticket-number-in-empty": Boolean(
                      item === "0" || item === "#"
                    ),
                  })}
                >
                  {item === "0" || item === "#" ? "" : item}
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
    <div className="w-full flex flex-wrap flex-row items-start justify-start mt-2">
      <div className="bingo-table-cont">
        <div className="bingo-table-col">
          <div className="bingo-col-1">Schein Nr.</div>
          <div className="bingo-col-2">{bingoData?.coupon_no}</div>
        </div>
      </div>
      <div className="bingo-table-cont">
        <div className="bingo-table-col">
          <div className="bingo-col-1">Spielstatus</div>
          <div className="bingo-col-2">
            {gameDetail?.gameStatus === "finished"
              ? "Beendet - Spiel ist vorbei"
              : "Läuft"}
          </div>
        </div>
      </div>
      {/* Total Winnings Display */}
      {(() => {
      const totalWinnings = cardInfo?.reduce((total, card) => total + card.win_amount, 0) || 0;
      return (
        <>
          <div className="bingo-table-cont">
            <div
              className="bingo-table-col"
              style={{
                backgroundColor: totalWinnings === 0 ? "#ef4444" : "#22c55e",
                color: "white",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              <div className="bingo-col-1">Gesamtgewinn</div>
              <div className="bingo-col-2">{totalWinnings}</div>
            </div>
          </div>

          {/* Print button section - only shown if totalWinnings > 0 */}
          {totalWinnings > 0 && (
            <div className="bingo-table-cont">
              <div className="bingo-table-col">
                <div className="pay-coupon-cont">
                  <div onClick={() => this._sendDirectPrintbingo(bingoData, 'en')}>
                    <FormattedMessage
                      id="lang_direct_print"
                      defaultMessage="lang_direct_print"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
    })()}

    </div>
    {/* Renk Anlamları */}
    <div className="w-full mt-4">
      <div className="flex items-center mb-2">
        <div
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: "#22c55e",
            marginRight: "8px",
          }}
        ></div>
        <span>Grün: Gewinn</span>
      </div>
      <div className="flex items-center mb-2">
        <div
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: "#ef4444",
            marginRight: "8px",
          }}
        ></div>
        <span>Rot: Verloren</span>
      </div>
      <div className="flex items-center">
        <div
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: "#eab308",
            marginRight: "8px",
          }}
        ></div>
        <span>Gelb: Wartend</span>
      </div>
    </div>
  </>
)}

            </div>
            <UpDown size="md" upClick={() => {}} downClick={() => {}} />
          </div>
        )}
      </div>
    );
  }
}

const msp = (state) => {
  return {
    language: state.language,
    member: state.member,
    printedTickets: state.printed_tickets,
  };
};

export default connect(msp)(injectIntl(TicketCheck));
