import React, { useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { FlatList } from "react-native";
import { connect } from "react-redux";
import { Redux, WebClient, constants } from "../../../components";
// import assets from "../../../components/utils/assets";
import CButton from "./components/button";
import CButtonLP from "./components/long-press-button";
import moment from "moment";
// import { matchJson } from "../../../components/utils/models/match";
import { useRef } from "react";
// import SearchOverlay from "./components/search-overlay";
import CStatisticsIframe from "./components/statistics";
import CMatchDetail from "./components/live-match-detail";
import _, { filter } from "lodash";

import MatchList from "./components/live-match-list";
import Match from "../../../components/models/match";

import classNames from "classnames";
// import SocketUtil from "../../../components/socket";
import Coupon from "./coupon";

import timeFilter from "../../../components/utils/helpers/timeFilter";

// let tournament_title = null;
let scrollM = 0;
// let scrollV = 0;

// let active_page = 1;

// let schema = []
let z = 0;
let l = 0;
let dh = 0;

class LiveMatches extends React.Component {
  constructor(props) {
    super(props);
    this.wc = new WebClient();
    this.matchListDiv = React.createRef();
    this.interval = undefined;
    this.state = {
      list: [0, 0, 0, 0, 0, 0],
      activeSport: { sportId: 1, sportName: "Football" },
      activeCategory: { id: -1 },
      tournaments: [],
      activeTournament: null,
      sports: [],
      categories: [],
      timeSelection: 1,
      daySelection: -1,
      start: new Date(),
      end: moment(new Date(), "DD-MM-YYYY").add(6, "hours"),
      matches: [],
      dayList: [],
      keyword: "",
      showSearchOverlay: false,
      showStatistics: false,
      statisticsUrl: "",
      matchDetail: [],
      showMatchDetail: false,
      selectedMatch: null,
      showAllList: false,
      allTypeNames: [],
      typenames: [],
      ready: false,
      listReady: false,
      onlyFavorites: false,
      schema: [],
      totalRows: 0,
      favoritesCount: 0,
    };
  }

  componentDidMount() {
    this._getMatches(this.state.activeSport);
  }

  _getMatches(e) {
    this.setState({ matches: [], listReady: false, activeSport: e });

    Redux.setLiveActiveSport(e.sportId);
    let odds = Redux.getLiveOddsBySportId(e.sportId);
    // console.log("first", odds);

    const typenames = Redux.getLiveTypeNames(e.sportId);

    const start = new Date();
    const end = moment(new Date(), "DD-MM-YYYY").add(6, "hours");

    const selected_tabs = Redux.getSelectedLiveTabs(e.sportId);
    // const { start, end } = this.state

    let d = {
      startDate: moment.utc(start).format("YYYY-MM-DD HH:mm"),
      endDate: moment.utc(end).format("YYYY-MM-DD HH:mm"),
      sportId: e.sportId,
      odds,
      limit: 200,
      sportCountLive: 1,
    };

    this.wc
      .post(
        constants.uris.live,
        d,
        {
          setLang: true,
          setOperatorId: true,
        },
        false
      )
      .then((response) => {
        if (response.status) {
          dh = this.matchListDiv.clientHeight;

          // let first = response.matches[0];

          let z = response.matches.filter((x) => x.betstatus === "started");

          if (z.length > 0) {
            z = _.orderBy(z, ["matchtime"], ["asc"]);
            // first = z[0];
          }

          // const first_odds = first?.odds ?? [];

          const _odd = [];
          odds.forEach((e) => {
            e.odds.forEach((f) => {
              _odd.push(f);
            });
          });

          this._generateTitleSchema(_odd, selected_tabs, response.matches);

          const _m = [];
          if (response.matches.length > 0) {
            response.matches.forEach((m) => {
              if (timeFilter(m)) return;
              _m.push(new Match(m));
            });
          }

          Redux.setMid(null);
          Redux.setLiveMatches(_m);
          this.setState({
            sports: response.sports,
            typenames,
            ready: true,
            listReady: true,
            totalRows: response.totalRows,
          });

          this._setLiveMatchTimer();
        }
      });
  }

  _setLiveMatchTimer() {
    if (this.interval !== undefined) clearInterval(this.interval);
    this.interval = setInterval(() => {
      // console.log("SCREEN SAVER")
      // this.props.onIdle()
      this._getMatchScheduled(this.state.activeSport.sportId);
    }, constants.idle_timer_minute * 60000);
  }

  _getMatchScheduled(sportId) {
    let odds = Redux.getLiveOddsBySportId(sportId);
    // const { start, end } = this.state
    const start = new Date();
    const end = moment(new Date(), "DD-MM-YYYY").add(6, "hours");

    let d = {
      startDate: moment(start).format("YYYY-MM-DD HH:mm"),
      endDate: moment(end).format("YYYY-MM-DD HH:mm"),
      sportId: sportId,
      odds,
      limit: 200,
      sportCountLive: 1,
    };

    this.wc
      .post(
        constants.uris.live,
        d,
        {
          setLang: true,
          setOperatorId: true,
        },
        false
      )
      .then((response) => {
        if (response.status) {
          let lm = this.props.live_matches;
          let m = response.matches;

          lm.map((e) => {
            if (!m.find((x) => x.matchId == e.id)) {
              e.remove();
            }
          });

          m.map((e) => {
            if (!lm.find((x) => x.id == e.matchId)) {
              lm.push(new Match(e));
              Redux.setStatusChanged(e.matchId);
            } else {
              let z = lm.filter((x) => x.id == e.matchId)[0];
              z.check(e);
            }
          });

          // if (m.length > 0) {
          //   let _m = []
          //   m.map((m) => {
          //     _m.push(new Match(m))
          //   })
          //   Redux.setLiveMatches(_m)
          // }

          this.setState({
            sports: response.sports,
            totalRows: response.totalRows,
          });
        }
      });
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  _generateTitleSchema(odds, selected_tabs, matches) {
    let schema = [];
    let markets = [];

    z = 3;
    l = 3;
    schema.push({ y: "d", o: [], order: 0 });
    schema.push({ y: "t", o: [], order: 0 });
    // console.log("matches", matches);
    odds.map((el) => {
      let odd_type = undefined;
      // console.log("test el", el);
      for (let q = 0; q < matches.length; q++) {
        // console.log("test q", q);
        let qz = matches[q];
        // console.log("qz", qz);
        let qq = qz?.odds?.filter((x) => x.oddstype == el.oid);
        // console.log("qq", qq);
        if (qq.length > 0) {
          odd_type = qq[0];
          break;
        }
      }

      if (odd_type !== undefined) {
        let has_special = false;

        matches.map((e) => {
          e.odds
            .filter((x) => x.oddstype == el.oid)
            .map((f) => {
              if (f.specialvalue != "" && f.oddstype == 18) {
                has_special = true;
              }
            });
        });
        console.log("marketsss", markets);
        let first_half = [];

        let has_first_half = el?.ht ? true : false;
        let is_first_half = Boolean(
          odds.filter((x) => x.ht == el.oid)?.length > 0
        );

        if (!is_first_half) {
          if (has_first_half) {
            let first_half_odds = undefined;
            for (let q = 0; q < matches.length; q++) {
              let qq = matches[q].odds?.filter((x) => x.oddstype == el.ht);
              if (qq.length > 0) {
                first_half_odds = qq[0];
                break;
              }
            }

            if (first_half_odds) {
              if (has_special) {
                first_half.push({
                  o: ".s",
                  i: first_half_odds.odds[0].outComeId,
                  t: el.ht,
                });
              }

              first_half_odds.odds.map((se) => {
                first_half.push({
                  o: se.outCome
                    .replace(" ({0})", "")
                    .replace(" ({h})", "")

                    .replace("{sw}", ""),

                  i: se.outComeId,
                  t: el.ht,
                });
              });
            }
          }

          let o = [];
          if (has_special) {
            o.push({
              o: ".s",
              i: odd_type.odds[0].outComeId,
              t: odd_type.odds[0].oddsType,
            });
          }

          odd_type.odds.map((se) => {
            o.push({
              o: se.outCome
                .replace(" ({0})", "")
                .replace(" ({h})", "")
                .replace("{sw}", "")
                .replace("{$competitor1}", "1")
                .replace("{$competitor2}", "2")
                .replace("{total}", "")
                .replace("Draw", "X")
                .replace("draw", "X")
                .replace("under", "-")
                .replace("over", "+")
                .replace("none", "X"),
              i: se.outComeId,
              t: el.oid,
            });
          });
          markets.push({
            y: "o",
            t: odd_type.typeName.replace("{sw}", "").replace("{!goalnr} ", ""),
            o,
            s: has_special,
            i: el.oid,
            fh: first_half,
            hf: has_first_half,
            ics: false,
          });
        }
      }
    });
    // console.log("test markets", markets);

    let column = 0;
    let filtered = markets.filter((x) =>
      selected_tabs.slice(0, 4).find((y) => y == x.i)
    );
    // console.log("TEST FILTER", filtered);
    // console.log("----->", filtered)

    filtered.map((f) => {
      let _o =
        selected_tabs.indexOf(f.i) == -1
          ? 1000
          : selected_tabs.indexOf(f.i) * 10;

      f.ics = true;
      f.order = _o;
      schema.push(f);
      column++;
      l++;
      z = z + f.o.length;
    });

    // filtered = markets.filter(x => !selected_tabs.includes(x.i))

    // for(let i = 0; i < filtered.length; i++) {
    //   let _z = Boolean(column < 4)
    //   let _o = selected_tabs.indexOf(filtered[i].i) == -1 ? 1000 : (selected_tabs.indexOf(filtered[i].i) * 10)
    //   filtered[i].ics = _z ? true : false
    //   filtered[i].order = _o
    //   schema.push(filtered[i])
    //   if (_z) {
    //     l++;
    //     column++;
    //     z = z + filtered[i].o.length
    //   }
    // }

    schema.push({ y: "s", o: [], order: 10000 });
    schema.push({ y: "a", o: [], order: 10000 });

    schema = _.orderBy(schema, ["order"], ["asc"]);

    let stabs = schema
      .filter((x) => x.y == "o" && x.ics == true)
      .reduce((a, o) => (a.push(o.i), a), []);
    Redux.updateSelectedLiveTabs(this.state.activeSport.sportId, stabs);

    this.setState({ schema });

    // console.log("schema clg", schema);
  }

  _getMatchesContinue(data) {
    this.wc
      .post(
        constants.uris.mtch,
        data,
        {
          setLang: true,
          setOperatorId: true,
        },
        false
      )
      .then((response) => {
        if (response.status) {
          let c = [...response.results];
          c.splice(0, 75);
          let a = [...this.state.matches, ...c];
          this.setState({ matches: a });
        }
      })
      .catch((err) => {});
  }

  _getTournamentMatches(tournament) {
    const { activeSport, activeCategory } = this.state;
    this.setState({ activeTournament: tournament }, () => {
      this._getMatches(activeSport.id, activeCategory.id, tournament.id);
    });
  }

  _leftArrow() {
    if (scrollM > 0) {
      scrollM = scrollM - 1;
      this.l.scrollToIndex({ animated: true, index: "" + scrollM });
    } else {
      scrollM = this.state.sports.length - 1;
      this.l.scrollToIndex({ animated: false, index: "" + scrollM });
    }
  }

  _rightArrow() {
    if (scrollM < this.state.sports.length - 1) {
      scrollM = scrollM + 1;
      this.l.scrollToIndex({ animated: true, index: "" + scrollM });
    } else {
      scrollM = 0;
      this.l.scrollToIndex({ animated: false, index: "" + scrollM });
    }
  }

  _getToday() {
    var start = new Date();
    var end = new Date();

    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    this.setState(
      { timeSelection: 2, daySelection: -1, start, end, keyword: "" },
      () => {
        this._getSports();
      }
    );
  }

  _get6Hours() {
    let start = new Date();
    let end = moment(start, "DD-MM-YYYY").add(6, "hours");
    this.setState(
      { timeSelection: 1, daySelection: -1, start, end, keyword: "" },
      () => {
        this._getSports();
      }
    );
  }

  _getAll() {
    let start = new Date();
    let end = moment(start, "DD-MM-YYYY").add(45, "days");
    this.setState(
      { timeSelection: 0, daySelection: -1, start, end, keyword: "" },
      () => {
        this._getSports();
      }
    );
  }

  _getByDay(st, index) {
    let start = st.toDate();
    start.setHours(0, 0, 0, 0);
    var end = moment(start, "DD-MM-YYYY").add(1, "days");

    this.setState(
      { daySelection: index, timeSelection: -1, start, end, keyword: "" },
      () => {
        this._getSports();
      }
    );
  }

  _search() {
    const { activeSport, keyword } = this.state;
    this._getMatches(activeSport.id, undefined, undefined, keyword);
  }

  _getMatchDetail(e) {
    this.wc
      .post(
        constants.uris.livo,
        {
          matchId: e.id,
          getFull: 1,
        },
        {
          setLang: true,
          setOperatorId: true,
          setUserId: true,
        }
      )
      .then((response) => {
        if (response.status == "OK") {
          e.detail(response.odds);
          this.setState({ selectedMatch: e, showMatchDetail: true });
        }
      });
  }

  _changeOrderType(id) {
    this.setState({ timeSelection: id, listReady: false }, () => {
      setTimeout(() => {
        this.setState({ listReady: true });
      }, 50);
    });
  }

  _updateTab(s, p) {
    const { activeSport, schema } = this.state;

    let selected_tabs = Redux.getSelectedLiveTabs(activeSport.sportId);
    var indexs = selected_tabs.indexOf(s);
    var indexp = selected_tabs.indexOf(p);

    if (indexs != -1) selected_tabs[indexs] = p;
    if (indexp != -1) selected_tabs[indexp] = s;

    Redux.updateSelectedLiveTabs(activeSport.sportId, selected_tabs);

    let l = [];
    schema.map((e) => {
      if (e.y != "o") l.push(e);
      else {
        let _s = selected_tabs.indexOf(e.i);
        e.ics = false;
        e.order = 1000;
        if (_s != -1) {
          e.ics = true;
          e.order = _s * 10;
        }
        l.push(e);
      }
    });

    l = _.orderBy(l, ["order"], ["asc"]);
    this.setState({ schema: l });
  }

  _getHalfTime() {
    if (this.state.activeSport.sportId == 1) {
      return 45;
    }
  }

  render() {
    const { live_matches } = this.props;
    const {
      onlyFavorites,
      favoritesCount,
      sports,
      activeSport,
      listReady,
      timeSelection,
      schema,
      showStatistics,
      statisticsUrl,
      matchDetail,
      showMatchDetail,
      selectedMatch,
      showAllList,
      // ready,
      typenames,
    } = this.state;

    const width = window.innerWidth;
    const showStaticCoupon = Boolean(width > 1200);

    return (
      <div
        onClick={() => {
          // this._setLiveMatchTimer()
        }}
        className={classNames({
          "in-page-cont": !showStaticCoupon,
          "in-page-cont-2": showStaticCoupon,
        })}
      >
        <div className={classNames({ "in-page-layout-1": showStaticCoupon })}>
          <div className="in-page-top">
            <div
              className={classNames({
                "live-in-page-top-col1": !showStaticCoupon,
                "live-in-page-top-col1-2": showStaticCoupon,
              })}
            >
              <CButton
                width="w-5ch"
                butid="firstarrow"
                icon={true}
                iconName="fa-chevron-left"
                onClick={() => {
                  this._leftArrow();
                }}
              />

              <div className="in-page-top-col2-mid">
                <FlatList
                  ref={(ref) => {
                    this.l = ref;
                  }}
                  data={sports}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                  horizontal={true}
                  keyExtractor={(item, index) => item.sportId}
                  renderItem={({ item, index }) => {
                    return (
                      <CButton
                        key={item.sportId}
                        width="w-spe2"
                        uppercase="uppercase"
                        isSport={true}
                        sportId={item.sportId}
                        active={Boolean(activeSport?.sportId == item.sportId)}
                        text={`${item.sportName} (${
                          item?.inPlay > 0 ? item.inPlay : item.liveCount
                        })`}
                        onClick={() => {
                          this._getMatches(item);
                        }}
                      />
                    );
                  }}
                />
              </div>

              <CButton
                width="w-1/12"
                icon={true}
                butid="lastarrow"
                iconName="fa-chevron-right"
                onClick={() => {
                  this._rightArrow();
                }}
              />
            </div>

            {/* <div
              className={classNames({
                "live-in-page-top-col2": !showStaticCoupon,
                "live-in-page-top-col2-2": showStaticCoupon,
              })}
            >
              <CButton
                uppercase="uppercase"
                translate={true}
                width={showStaticCoupon ? "w-1/2" : "w-1/3"}
                text="lang_order_by_country"
                active={Boolean(timeSelection == 0)}
                onClick={() => {
                  this._changeOrderType(0);
                }}
              />
              <CButton
                uppercase="uppercase"
                translate={true}
                width={showStaticCoupon ? "w-1/2" : "w-1/3"}
                text="lang_order_by_date"
                active={Boolean(timeSelection == 1)}
                onClick={() => {
                  this._changeOrderType(1);
                }}
              />
              {!showStaticCoupon && (
                <CButton
                  uppercase="uppercase"
                  width="w-1/3"
                  translate={true}
                  text="lang_coupon"
                  onClick={() => {
                    this.props.onCouponOpen();
                  }}
                />
              )}
            </div> */}
          </div>

          <div className="live-in-page-middle">
            <div className="match-title-cont-lv">
              {schema.map((s, sindex) => {
                if (s.y == "o" && !s.ics) return null;

                return (
                  <div
                    key={sindex}
                    data-box={z}
                    data-col={l}
                    className={`t-${s.y} t-o-${s.o?.length ?? 0}`}
                  >
                    {s.y == "d" && <p></p>}

                    {s.y == "t" && <p>{activeSport?.sportName}</p>}

                    {s.y == "o" && s.ics && (
                      <div
                        style={{ marginTop: "-10px" }}
                        className="relative w-full"
                      >
                        <span
                          style={{
                            lineHeight: "14px",
                            marginBottom: "15px",
                          }}
                          className="text-center w-full"
                        >
                          {s.t
                            ?.replace("{pw}", "")
                            .split("(")[0][0]
                            .toUpperCase() +
                            s.t?.replace("{pw}", "").split("(")[0].slice(1)}
                        </span>
                        <div style={{ marginTop: "-10px" }} className="t-o-c">
                          {s.o.map((o, oindex) => {
                            return (
                              <div className="t-o-i" key={oindex}>
                                {o.o === ".s"
                                  ? ""
                                  : o.o.includes("unentschieden") ||
                                    o.o.includes("keines")
                                  ? "x"
                                  : o.o}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    {s.y == "s" && <div></div>}

                    {s.y == "a" && <div></div>}
                  </div>
                );
              })}
            </div>

            <div ref={this.matchListDiv} className="live-in-page-middle-right">
              {listReady && (
                <MatchList
                  time={new Date()}
                  dh={dh}
                  typenames={typenames}
                  schema={schema}
                  z={z}
                  l={l}
                  activeSport={activeSport}
                  orderBy={timeSelection}
                  onlyFavorites={onlyFavorites}
                  showAllList={showAllList}
                  onScroll={() => {
                    // this._setLiveMatchTimer()
                  }}
                  onRef={(e) => {
                    this.mlist = e;
                  }}
                  halfTime={this._getHalfTime()}
                  onDetail={(e) => {
                    this._getMatchDetail(e);
                  }}
                  onAddFavorite={(e) => {
                    this.setState({ favoritesCount: favoritesCount + e });
                  }}
                  onShowStatistics={(e) => {
                    this.setState({ showStatistics: true, statisticsUrl: e });
                  }}
                />
              )}
            </div>
          </div>

          <div className="live-in-page-bottom">
            <CButton
              width="w-1/3"
              mr="mr-m"
              uppercase="uppercase"
              butid="favaltbut"
              translate={true}
              text="lang_favorites"
              count={live_matches.filter((a) => a.is_favorite).length}
              active={onlyFavorites}
              onClick={() => {
                this.setState({ onlyFavorites: !onlyFavorites });
              }}
            />
            <div
              onClick={() => {
                this.matchListDiv.current.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <CButtonLP
                width="w-1/3"
                icon={true}
                butid="upaltbut"
                iconName="fa-chevron-up"
              />
            </div>

            <div
              onClick={() => {
                console.log("scrolling down");
                this.matchListDiv.current.scrollTo({
                  top: this.matchListDiv.current.scrollHeight,
                  behavior: "smooth",
                });
              }}
            >
              <CButtonLP
                width="w-1/3"
                icon={true}
                butid="downaltbut"
                iconName="fa-chevron-down"
              />
            </div>
          </div>
        </div>

        {showStaticCoupon && (
          <div className="static-coupon-container">
            <Coupon onClose={() => {}} hideClose={true} />
          </div>
        )}

        {showStatistics && (
          <CStatisticsIframe
            url={statisticsUrl}
            onClose={() => {
              this.setState({ showStatistics: false, statisticsUrl: null });
            }}
          />
        )}

        {showMatchDetail && (
          <>
            <CMatchDetail
              data={matchDetail}
              activeSport={activeSport}
              match={selectedMatch}
              onClose={() => {
                this.setState({ showMatchDetail: false, matchDetail: [] });
              }}
            />
          </>
        )}
      </div>
    );
  }
}

const msp = (state) => {
  return {
    live_matches: state.live_matches,
    sc: state._statusChanged,
  };
};

export default connect(msp)(injectIntl(LiveMatches));
