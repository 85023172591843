import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import AdminAccounts from './accounts';
import AdminTickets from './tickets';
import AdminTransactions from './transactions';

class ManagerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      showModal: false,
      showAccountDetailsModal: false,
      operatorUrl: '',
      accountDetailsUrl: 'https://operator.ibaterm.com/terminal/account_details.html',
      uname: localStorage.getItem('uname') || '',
    };
  }

  componentDidMount() {
    this.updateOperatorUrl();
    this.logAccountType();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account !== this.props.account) {
      this.logAccountType();
    }
  }

  logAccountType = () => {
    const { account } = this.props;
    console.log(`Account Type: ${account ? 'Account (true)' : 'Terminal (false)'}`);
  };

  updateOperatorUrl = () => {
    const terminalId = localStorage.getItem('TerminalId');
    const uname = localStorage.getItem('uname');
    const baseUrl = 'https://operator.ibaterm.com/terminal/indes.html';
    const url = `${baseUrl}?terminal=${terminalId}&uname=${uname}`;
    this.setState({ operatorUrl: url });
  };

  toggleModal = () => {
    if (!this.state.showModal) {
      this.updateOperatorUrl();
    }
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  toggleAccountDetailsModal = () => {
    const uname = localStorage.getItem('uname');
    const accountDetailsUrl = `https://operator.ibaterm.com/terminal/account_details.html?uname=${uname}`;
    this.setState((prevState) => ({
      showAccountDetailsModal: !prevState.showAccountDetailsModal,
      accountDetailsUrl,
    }));
  };

  renderModal() {
    if (!this.state.showModal) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-4 w-3/4 h-3/4 relative">
          <button
            onClick={this.toggleModal}
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
          >
            <i className="fas fa-times" />
          </button>
          <iframe
            src={this.state.operatorUrl}
            className="w-full h-full border-none"
            title="operator"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          />
        </div>
      </div>
    );
  }

  renderAccountDetailsModal() {
    if (!this.state.showAccountDetailsModal) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-4 w-3/4 h-3/4 relative">
          <button
            onClick={this.toggleAccountDetailsModal}
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
          >
            <i className="fas fa-times" />
          </button>
          <iframe
            src={this.state.accountDetailsUrl}
            className="w-full h-full border-none"
            title="account-details"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          />
        </div>
      </div>
    );
  }

  render() {
    const { activeTab } = this.state;
    const { account } = this.props;
    return (
      <div className="w-full h-full">
        <div className="manager-tabs">
          <div
            onClick={() => {
              this.setState({ activeTab: 0 });
            }}
            className={classNames({
              [`manager-tab-item`]: true,
              active: Boolean(activeTab === 0),
            })}
          >
            <i className="fas fa-users" />
            <span>
              <FormattedMessage id="lang_accounts" defaultMessage="lang_accounts" />
            </span>
          </div>
          <div
            onClick={() => {
              this.setState({ activeTab: 1 });
            }}
            className={classNames({
              [`manager-tab-item`]: true,
              active: Boolean(activeTab === 1),
            })}
          >
            <i className="fas fa-ticket-alt" />
            <span>
              <FormattedMessage id="lang_tickets" defaultMessage="lang_tickets" />
            </span>
          </div>
          <div
            onClick={() => {
              this.setState({ activeTab: 2 });
            }}
            className={classNames({
              [`manager-tab-item`]: true,
              active: Boolean(activeTab === 2),
            })}
          >
            <i className="fas fa-list" />
            <span>
              <FormattedMessage id="lang_transactions" defaultMessage="lang_transactions" />
            </span>
          </div>
          <div
            onClick={this.toggleAccountDetailsModal}
            className={classNames({
              [`manager-tab-item`]: true,
              active: Boolean(activeTab === 4),
            })}
          >
            <i className="fas fa-file-invoice" />
            <span>
              <FormattedMessage id="lang_account_details" defaultMessage="Account Details" />
            </span>
          </div>
          <div
            onClick={() => {
              this.props.onLogout();
            }}
            className={classNames({
              [`manager-tab-item`]: true,
              active: Boolean(activeTab === 5),
            })}
          >
            <i className="fas fa-sign-out-alt" />
            <span>
              <FormattedMessage id="lang_logout" defaultMessage="lang_logout" />
            </span>
          </div>
        </div>

        {activeTab === 0 && <AdminAccounts account={account} />} {/* Account prop'u gönderiliyor */}
            {activeTab === 1 && <AdminTickets account={account} />}  {/* Account prop'u gönderiliyor */}
            {activeTab === 2 && <AdminTransactions account={account} />} {/* Account prop'u gönderiliyor */}


        {this.renderModal()}
        {this.renderAccountDetailsModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.config,
});

export default connect(mapStateToProps)(injectIntl(ManagerPage));
