import React from 'react'
import { connect } from 'react-redux'
import Login from './auth'
import Main from './main'
import { injectIntl } from "react-intl"
import PrinterError from './error/printer_error'
import NetworkError from './error/network_error'
import Spinner from '../../components/spinner'
import { WebClient, constants, getUserData } from '../../components/utils/webclient'
import * as Redux from '../../components/redux'
import Swal from 'sweetalert2/src/sweetalert2.js'
import "../../../assets/themes/default/app.css"
import "@sweetalert2/themes/dark/dark.css"
import "../../../assets/common/grids.css"

let listener_initialized = false

class MainRoot extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
    if (window?.ipcRenderer) {
      this._checkPrinterStatus()
    } else {
      this.startAction()
    }    
  }

  _checkPrinterStatus() {
    if (window?.ipcRenderer && !listener_initialized) {
      window.ipcRenderer.on('printerStatusResponseMain', (event, args) => {
        if (args.status) {
          this.startAction()
        } else {
          Redux.setPrinterError(true)
        }
      })
      listener_initialized = true
    }
    if (window?.ipcRenderer) {
      window.ipcRenderer.send("printerStatusRequestMain", "_")
    }
  }

  async startAction() {
    const urlParams = new URLSearchParams(window.location.search)
    const username = urlParams.get("username") || await localStorage.getItem("uname")
    const password = urlParams.get("password")

    if (username && password) {
      this._login(username, password)
    } else if (username) {
      this._login(username)
    } else {
      Redux.setReady(true)
    }
  }

  _login(userName, password = "") {
    const wc = new WebClient()
    wc.post(constants.uris.logn, {
      userName,
      password,
      passwordType: "normal",
    }, {
      setReqId: true,
      setMachineId: true,
      setLang: true
    }).then((response) => {
      if (response.status) {
        Redux.setTerminalPass(response.terminalPass)
        Redux.setAccountPass(response.AccountPass)
        
        localStorage.setItem("uname", userName)
        getUserData(2, response.userId)
      } else {
        Redux.setReady(true)
      
      }
    }).catch((error) => {
      Swal.fire({
        title: this.props.intl.formatMessage({id: "lang_error_title", default_message: "lang_error_title"}),
        text: this.props.intl.formatMessage({id: "lang_an_error_occured", default_message: "lang_an_error_occured"}),
        icon: 'danger',
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false
      })
      Redux.setReady(true)
    })
  }

  render() {
    const { member, loading, ready, printer_error, connection_error, intl } = this.props

    if (connection_error) {
      return <NetworkError />
    }

    if (printer_error) {
      return <PrinterError />
    }

    if (!ready) {
      return (
        <div className="w-screen h-screen" style={{ backgroundColor: '#1c1e21' }}>
          <div className="loading-container">
            <Spinner />
          </div>
        </div>
      )
    }

    return (
      <div className="w-screen h-screen">
        {member == null ? <Login /> : <Main />}
        {loading && (
          <div className="loading-container">
            <Spinner />
          </div>
        )}
      </div>
    )
  }
}

const msp = (state) => {
  return {
    config: state.config,
    member: state.member,
    loading: state.loading,
    ready: state.ready,
    printer_error: state.printer_error,
    connection_error: state.connection_error
  }
}

export default connect(msp)(injectIntl(MainRoot))