import Axios from "axios";
import constants from "./constants_epics";
import { store } from "../redux";
import { Redux } from "..";
import io from "socket.io-client";

class SocketManager {
  constructor() {
    this.socket = null;
    this.isConnecting = false;
    this.reconnectTimer = null;
    this.lastDataTimestamp = Date.now();
    this.connectionCheckInterval = null;
    this.messageQueue = [];
    this.pendingMessages = new Set();
    
    this.RECONNECT_INTERVAL = 1500;
    this.SOCKET_TIMEOUT = 35000;
    this.CONNECTION_CHECK_INTERVAL = 5000;
    this.MAX_RECONNECT_ATTEMPTS = 5;
    this.reconnectAttempts = 0;
    
    this.handleConnect = this.handleConnect.bind(this);
    this.handleDisconnect = this.handleDisconnect.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleData = this.handleData.bind(this);
    this.processMessageQueue = this.processMessageQueue.bind(this);
  }

  initialize() {
    if (this.socket?.connected || this.isConnecting) {
      console.log('Socket already connected or connecting');
      return;
    }

    this.isConnecting = true;
    console.log('Initializing socket connection');

    if (this.socket) {
      this.cleanupSocket();
    }

    this.socket = io("wss://insckt.ibaterm.com", {
      transports: ["websocket"],
      autoConnect: false,
      reconnection: false,
    });

    this.attachEventListeners();
    this.socket.open();
    this.startConnectionCheck();
  }

  attachEventListeners() {
    this.socket.on("connect", this.handleConnect);
    this.socket.on("disconnect", this.handleDisconnect);
    this.socket.on("error", this.handleError);
    this.socket.on("data", this.handleData);
    
    this.socket.on("message_received", (msgId) => {
      this.pendingMessages.delete(msgId);
      console.log(`Message ${msgId} successfully delivered`);
    });
  }

  handleConnect() {
    console.log("Socket connected");
    this.isConnecting = false;
    this.reconnectAttempts = 0;
    clearTimeout(this.reconnectTimer);
    this.updateLastDataTimestamp();
    this.processMessageQueue();
  }

  handleDisconnect() {
    console.log('Socket disconnected');
    this.reconnect();
  }

  handleError(error) {
    console.error('Socket error:', error);
    this.reconnect();
  }

  handleData(data) {
    this.updateLastDataTimestamp();
    
    if (!data) {
      console.log("Empty or undefined data received");
      return;
    }
    if(data.msg.terminalId === localStorage.getItem("TerminalId") && data.msg?.type === "moneyup")
    {
     const userId= localStorage.getItem("userId");
    const uniqueid=data.msg.unique_id	;
      const wc = new WebClient();
      wc.post(constants.uris.sckupdt, { userId,uniqueid }, { setReqId: true, setLang: true })
        .then((response) => {
       console.log(response);
        })
        .catch((error) => {
       console.error(error);
        });
    }

    if (data.msg?.type === "moneyup" && data.msg.terminalId === localStorage.getItem("TerminalId")) {
      getUserData();
    }

    if (data.msgId) {
      this.socket.emit("message_ack", data.msgId);
    }
  }

  updateLastDataTimestamp() {
    this.lastDataTimestamp = Date.now();
    console.log('Last data timestamp updated:', new Date(this.lastDataTimestamp).toISOString());
  }

  startConnectionCheck() {
    if (this.connectionCheckInterval) {
      clearInterval(this.connectionCheckInterval);
    }

    this.connectionCheckInterval = setInterval(() => {
      const timeSinceLastData = Date.now() - this.lastDataTimestamp;
      if (timeSinceLastData > this.SOCKET_TIMEOUT && !this.isConnecting) {
        console.log('No data received for 35 seconds, reconnecting...');
        this.reconnect();
      }
    }, this.CONNECTION_CHECK_INTERVAL);
  }

  reconnect() {
    if (this.isConnecting) {
      console.log('Reconnection already in progress');
      return;
    }

    if (this.reconnectAttempts >= this.MAX_RECONNECT_ATTEMPTS) {
      console.log('Max reconnection attempts reached, waiting for manual intervention');
      this.reconnectAttempts = 0;
      Redux.showToast("error", "Connection lost. Please refresh the page.");
      return;
    }

    this.reconnectAttempts++;
    this.isConnecting = true;
    this.cleanupSocket();

    clearTimeout(this.reconnectTimer);
    this.reconnectTimer = setTimeout(() => {
      console.log(`Reconnection attempt ${this.reconnectAttempts}...`);
      this.initialize();
    }, this.RECONNECT_INTERVAL);
  }

  cleanupSocket() {
    if (this.socket) {
      this.socket.close();
      this.socket.removeAllListeners();
      this.socket = null;
    }
    this.isConnecting = false;
  }

  emitMessage(type, data = {}, retryCount = 3) {
    const msgId = Date.now().toString(36) + Math.random().toString(36).substr(2);
    const message = {
      msgId,
      msg: {
        terminalId: localStorage.getItem("TerminalId"),
        type,
        ...data
      }
    };

    if (!this.socket?.connected) {
      console.log(`Socket not connected, queuing message: ${msgId}`);
      this.messageQueue.push({ message, retryCount });
      return;
    }

    this.sendMessage(message, retryCount);
  }

  sendMessage(message, retryCount) {
    this.pendingMessages.add(message.msgId);
    
    this.socket.emit('terminal', message, (ack) => {
      if (ack?.received) {
        this.pendingMessages.delete(message.msgId);
      } else if (retryCount > 0) {
        console.log(`Retrying message ${message.msgId}, ${retryCount} attempts remaining`);
        setTimeout(() => {
          this.sendMessage(message, retryCount - 1);
        }, 1000);
      } else {
        console.error(`Failed to send message ${message.msgId} after all retry attempts`);
        Redux.showToast("error", "Failed to send message. Please try again.");
      }
    });

    setTimeout(() => {
      if (this.pendingMessages.has(message.msgId)) {
        console.log(`Message ${message.msgId} acknowledgment timeout`);
        if (retryCount > 0) {
          this.sendMessage(message, retryCount - 1);
        }
      }
    }, 5000);
  }

  processMessageQueue() {
    while (this.messageQueue.length > 0 && this.socket?.connected) {
      const { message, retryCount } = this.messageQueue.shift();
      console.log(`Processing queued message: ${message.msgId}`);
      this.sendMessage(message, retryCount);
    }
  }
}

const socketManager = new SocketManager();

const defaultPrefs = {
  setUserId: true,
  setReqId: true,
  setMachineId: true,
  setLang: true,
  setOperatorId: true,
  setToken: false,
};

class WebClient {
  constructor() {
    this.axiosInstance = Axios.create({
      baseURL: constants.base_url,
      headers: { "Content-Type": "application/json" },
    });
  }

  get = (path) => this.createProxyPromise(this.axiosInstance.get(path));

  post = (path, data, prefs = defaultPrefs, showLoading = true) => {
    const states = store.getState();
    const d = this.preparePostData(data, prefs, states);
    return this.createProxyPromise(this.axiosInstance.post(path, d), showLoading);
  };

  preparePostData(data, prefs, states) {
    const d = { ...data, site_id: states.settings.site_id };
    if (prefs.setLang) d.lang = localStorage.getItem("language");
    if (prefs.setMachineId) d.machineId = localStorage.getItem("TerminalId");
    if (prefs.setReqId) d.reqId = constants.reqId;
    if (prefs.setUserId) d.userId = states.member?.userId || "0";
    if (prefs.setOperatorId) d.operatorId = states.settings.operatorId;
    if (prefs.setToken) d.token = Math.random().toString(36).substring(2);
    if (prefs.setTokenWithUser) d.token = states.member.userId;
    if (prefs.setServerId) d.serverId = states.settings.serverid;
    return d;
  }

  createProxyPromise(realPromise, showLoading = true) {
    if (showLoading) Redux.showLoading();
    return realPromise
      .then(this.responseHandler)
      .catch(this.errorHandler)
      .finally(() => Redux.hideLoading());
  }

  responseHandler = (response) => response.data;

  errorHandler = (error) => {
    if (error.response !== undefined) {
      switch (error.response.status) {
        case 401:
          console.log("Session expired or terminated! Please try logging in again.");
          break;
        case 405:
          console.log("Invalid request method!");
          break;
        case 403:
          console.log(error.response.data.message);
          break;
        default:
          console.log("Unexpected error:", error.response.status, error.response.data);
      }
    } else {
      console.log("An error occurred:", error.message);
    }
    throw error;
  };
}

function getUserData(type = 1, uid = null) {
  const userId = type === 2 ? uid : store.getState().member.userId;
  localStorage.setItem("userId", userId);

  const wc = new WebClient();
  wc.post(constants.uris.data, { userId }, { setReqId: true, setLang: true })
    .then((response) => {
      if (response.status) {
        Redux.setMemberData({ userId, ...response });
        if (response?.terminalNo) localStorage.setItem("TerminalId", response.terminalNo);
        if (type === 2) getBingoUrl();
      } else {
        if (response.status === false && response.logout === true) {
          socketManager.emitMessage("logout");
        }
        Redux.setReady(true);
        Redux.showToast("warning", response.error);
      }
    })
    .catch((error) => {
      Redux.setReady(true);
      Redux.showToast("error", "An error occurred");
    });
}

function getBingoUrl() {
  const wc = new WebClient();
  wc.post(
    constants.uris.bngo,
    {
      token: Math.random().toString(36).substr(2),
    },
    {
      setReqId: true,
      setMachineId: false,
      setUserId: true,
      setLang: true,
    }
  ).then((response) => {
    if (!response.status) {
      return;
    }
    const config = store.getState().config;
    const video = config.settings.secondScreen === "TombalaVideo" ? 0 : 1;
    const gameUrl = `${response.content.gameurl}&terminal=1&video=${video}&sendData=1&origin=${window.location.origin}`;
    Redux.setBingoUrl(gameUrl);

    if (config.settings.secondScreen === "TombalaVideo") {
      const a = response?.content?.gameurl?.split("?token");
      if (a?.length > 0) {
        const gameUrlForSecondScreen = `${a[0]}/#/secondScreen?token${a[1]}`;
        localStorage.setItem("bingoUrl", gameUrlForSecondScreen);
      }
    }
    console.log("loginSucceeded", "_");
  });
}

function GetSettings(onSuccess = () => {}, onError = () => {}) {
  const cc = new WebClient();
  cc.get("https://data.apiepics.com/v1/settings/get?domain=pino24.com")
    .then((response) => {
      if (response?.generalJs) {
        const script = document.createElement("script");
        script.src = "/settings/getsettingslocal.js";
        script.async = true;
        document.body.appendChild(script);
      }
      const site_interval = setInterval(() => {
        if (!window?.site_id) return;
        clearInterval(site_interval);
        constants.base_url = `https://api.ibaterm.com`;
        Redux.setSettings({ ...response, site_id: window?.site_id || null, operatorId: response.operatorId });
        onSuccess();
        SetConnectionListener();
      }, 100);
    })
    .catch((error) => {
      Redux.showToast("error", "Could not load settings. Please try again later.");
      onError();
      SetConnectionListener();
    });
}

function LangChanger(lang = 'en') {
  const wc = new WebClient();
  wc.post(
    constants.uris.lngcnhg,
    {},
    {
      setReqId: true,
      setLang: true,
      setUserId: true,
    }
  )
    .then((response) => {
      if (response.status) {
        console.log("Language changed successfully");
      } else {
        Redux.setReady(true);
        Redux.showToast("warning", response.error);
      }
    })
    .catch((error) => {
      Redux.setReady(true);
      Redux.showToast("error", "Error changing language");
    });
}

function SetConnectionListener() {
  window.addEventListener("online", onlineStatusChange);
  window.addEventListener("offline", onlineStatusChange);
}

function onlineStatusChange() {
  const a = store.getState().settings;
  Redux.setConnectionError(!navigator.onLine);
  if (navigator.onLine && a === null) {
    GetSettings();
  }

  if (navigator.onLine) {
    console.log("OpenCoinService", "_");
  } else {
    console.log("CloseCoinService", "_");
  }
}

function tF(val) {
  const w = window?.settings?.toFixedNumber ?? 2;
  return parseFloat(val).toFixed(parseFloat(val) > 1.001 && parseFloat(val) < 1.009 ? 3 : w);
}

socketManager.initialize();

export {
  WebClient,
  constants,
  getUserData,
  GetSettings,
  tF,
  LangChanger,
  getBingoUrl,
  socketManager
};

export const emitTerminalMessage = (action, data = {}) => socketManager.emitMessage(action, data);
export const emitTerminalMessageremote = (action, data = {}) => socketManager.emitMessage(action, data);
