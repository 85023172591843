import React from 'react'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { injectIntl } from "react-intl"
import { Redux, WebClient, constants, UpDown } from '../../../components'
import LeftList from './components/left-list-casino'
import RightList from './components/right-list-casino'
import SubItemList from './components/sub-item-list-casino'
import CIframe from './components/iframe'
import { getUserData } from '../../../components/utils/webclient'

let scrollM = 0

class LiveCasino extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      activeServiceId: "",
      activeSubServiceId: "",
      activeService: null,
      activeSubService: null,
      results: [],
      categories: [],
      // subItems: [],
      // items: [],
      gameUrl: null,
      showIframe: false
    }
  } 

  componentDidMount() {
    this._getLiveCasinos()
  }

  _getLiveCasinos() {
    let cc = new WebClient();
    cc.post(constants.uris.csnw, 
      {
        masterServiceId: "4",
        serverId: "1"
      }, 
      {
        setUserId: true,
        setLang: true,
        setOperatorId: true,
      }).then((response) => {

        if (response.status) {
          let results = response.results

          let activeServiceId = "";
          let activeService = null;
          let activeSubService = null;
          let activeSubServiceId = "";

          if (results.length > 0) {
            activeServiceId = results[0].sid;
            activeService = results[0]

            if (activeService?.c?.length > 0) {
              activeSubService = activeService.c[0]
              activeSubServiceId = activeSubService.sid
            }
          }

          this.setState({results: results, loading: false, activeServiceId, activeService, activeSubService, activeSubServiceId})
        }
      }).catch((err) => {
          
      })
  }
  
  _getSubItems(e) {
    let f = e.c
    let activeSubService = f[0]
    this.setState({activeService: e, activeServiceId: e.sid, activeSubServiceId: activeSubService.sid, activeSubService})
  }

  _getItems(e) {
    this.setState({activeSubService: e, activeSubServiceId: e.sid})
  }

  _getGame(item) {  

    const { activeService, activeSubService } = this.state

    let cc = new WebClient();
    cc.post(constants.uris.opcs, 
      {
        masterServiceId: activeSubService.mid,
        serviceId: item.sid,
        serverId: 1
      }, 
      {
        setUserId: true,
        setOperatorId: true,
        setLang: true,
        setToken: true
      }).then((response) => {
        if (response.status) {
          this.setState({showIframe: true, gameUrl: response.content.gameurl})
        } else {
          Redux.showToast("warning", response.msg)
        }
      }).catch((err) => {

      })    
  }


  render() {

    const { loading, categories, activeServiceId, activeSubServiceId, showIframe, gameUrl, results, activeService, activeSubService } = this.state

    let w = window.innerWidth;
    let ww = (w * 80) / 100
    let h = (ww / 3) * 0.75;

    let items = []

    return (
      <div className="w-full h-full">
        <div className="c-c">

          <LeftList 
            onItemClick={(e) => { this._getSubItems(e) }}
            categories={results} 
            activeServiceId={activeServiceId}
          />

          <div className="r-w-arrows">
              
            <SubItemList 
              onItemClick={(e) => { this._getItems(e) }}
              subItems={activeService?.c ?? []} 
              activeSubServiceId={activeSubServiceId} 
            />

            <RightList 
              items={activeSubService?.g ?? []} 
              subItems={activeService?.c ?? []} 
              onItemClick={(e) => { this._getGame(e) }} 
            />

          </div>
        </div>

        {
        showIframe && (
          <CIframe gameUrl={gameUrl}  openticket={() => {
            this.props.onTicketCheckClick();
          }} onClose={() => { this.setState({showIframe: false, gameUrl: null}, () => { getUserData() }) }} />
        )
        }

      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(injectIntl(LiveCasino))
