// import { formatDate } from "@/helpers/date";
import Market from "./market";
import moment from "moment";
import { Redux } from "..";
import { store } from "../redux";

function getMinutes(d) {
  return Math.round((new Date(d).getTime() - new Date().getTime()) / 1000 / 60);
}

function setArranger(m) {
  let outer = [];
  let a = m.split("-");
  a.map((_a) => {
    let s = _a.trim();
    outer.push({
      h: s.split(":")[0],
      a: s.split(":")[1],
    });
  });
  return outer;
}

function order(m) {
  let order = 0;
  if (!m.l) {
    order -= 10000 + getMinutes(m.md);
    return order;
  }
  if (m.sid == 3) {
    let kSaniye = 0;
    if (m.tiee) {
      kSaniye =
        parseInt(m.tiee.split(":")[0]) * 60 + parseInt(m.tiee.split(":")[1]);
      let oPeriod = (parseInt(m.ms.replace(/\D/g, "")) - 1) * m.pl * 60;
      let oSaniye = m.pl * 60 - kSaniye;
      let tSaniye = oSaniye + oPeriod;
      order += tSaniye;
    }
  } else if (m.sid == 4 || m.sid == 5) {
    order += parseInt(m.ms.replace("set", ""));
  } else if (m.sid === 1) {
    const customOrderList = [
      566, // uefa şampiyonlar ligi
      1861, // uefa avrupa ligi
      18278410, //konferans ligi
      1863, // uefa gencler ligi
      18260607, //"UEFA Avrupa Ligi - Kartlar ve Köşe Vuruşları"
      18279512, //"UEFA Konferans Ligi - Kartlar ve Köşe Vuruşları"
      18277589, //"World Cup Europe Qualification"
      18279662, //"World Cup Europe Qualification Cards and Corners"
      3013, // Turkiye Super Ligi
      3014, // Turkiye 1. Lig
      3015, // Turkiye Kupasi
      538, // Ingiltere Premier Lig
      541, // Almanya Bundesliga
      543, // Italya Serie A
      545, // Ispanya La Liga
      548, // Fransa 1.Lig
      1957, // Hollanda Eredivisie
      557, // Belcika Jupiler Pro Ligi
      1993, // Rusya Premier Ligi
      560, // Portekiz Primeira Liga
      3007, // Isvicre Super Lig
    ];

    const i = customOrderList.findIndex((x) => x === m.tid);
    if (i !== -1) {
      const calcIndex = customOrderList.length - i + 1;
      order += 100_000 + calcIndex * 100 + m.ti + (m.tie || 0);
    } else if (m.ms === "1p") {
      order += 100 + m.ti + (m.tie || 0);
    } else if (m.ms === "2p") {
      order += 300 + m.ti + (m.tie || 0);
    } else if (m.ms === "paused") {
      order += 200;
    }
    return order;
  }

  return order;
}

function isToday(d) {
  let t = new Date();
  let md = new Date(d);
  return (
    md.getDate() == t.getDate() &&
    md.getMonth() == t.getMonth() &&
    md.getFullYear() == t.getFullYear()
  );
}

function arrangeDate(d) {
  let today = isToday(d);
  let minutes = getMinutes(d);
  if (today) {
    if (minutes < 60 && minutes > 0)
      // return `${minutes}<br/>${window.app.$t("minute").substr(0, 3)}`;
      return `Today ${moment(d).format("HH:mm")}`;
    else if (minutes <= 0) return `Today ${moment(d).format("HH:mm")}`;
    // else return `${window.app.$t("today")} ${formatDate(d, "HH:mm")}`;
    else return `Today ${moment(d).format("HH:mm")}`;
  } else return moment(d).format("dd/MM<br/>HH:mm");
}

function convertScore(scr) {
  let a = scr.split(":");
  return {
    h: a[0],
    a: a[1],
  };
}

function isHalfTime(matchstatus, sportid, ti) {
  return Boolean(sportid === 1 && matchstatus === "1p" && ti < 43);
}

function findClass(ms) {
  
  if (Boolean(ms != "paused" && ms != "stopped" && ms != "not_started"))
    return "bg-live";
  if (Boolean(ms == "not_started")) return "bg-not-started";
  if (Boolean(ms == "paused")) return "bg-half-time";
  if (Boolean(ms == "stopped")) return "bg-stopped";
  return "";
}

class Match {
  constructor(m, detailOdds = null) {
    let hasTv = false;
    // let hasTv = box.tvlist.find(tl => tl == m.id);
    this.oc = 0;
    if (m.id) this.id = m.id; // matchId
    if (m.eid && m.eid != "0") this.eid = m.eid; // External matchId
    if (m.sportId) this.sid = m.sportId; // sportId
    if (m.Home) this.h = m.Home; // home
    if (m.Away) this.a = m.Away; // away
    if (m.homeTeam) this.h = m.homeTeam; // home
    if (m.awayTeam) this.a = m.awayTeam; // away
    if (m.HomeId) this.hid = m.HomeId; // homeId
    if (m.AwayId) this.aid = m.AwayId; // awayId
    if (m.homeTeamId) this.hid = m.homeTeamId; // homeId
    if (m.awayTeamId) this.aid = m.awayTeamId; // awayId
    if (m.matchDate) {
      this.d = arrangeDate(m.matchDate);
      this.md = m.matchDate;
    } //matchdate
    if (m.categoryId) this.cid = m.categoryId; // categoryId
    if (m.tournamentId) this.tid = m.tournamentId; // tournamentId
    if (m.categoryName) this.cn = m.categoryName; //categoryname
    if (m.tournamentName) this.tn = m.tournamentName; //tournamentname
    if (m.periodLength) this.pl = m.periodLength; // periodlength / matchlength
    if (m.pc) this.pc = m.pc; // periodcount
    // if (m.la) this.la = m.la;
    // else if (m.ms == "not_started") this.la = true; // liveActive - will be live
    if (m.matchstatus) this.ms = m.matchstatus; // matchStatus
    if (m.hasOwnProperty("betstatus")) {
      this.bs = m.betstatus; // betStatus
      this.iss = m.betstatus == "stopped" ? true : false;
      this.isv = m.betstatus == "stopped" ? false : true;
    }
    if (m.hasOwnProperty("iss")) this.iss = m.iss;
    if (m.hasOwnProperty("isv")) this.isv = m.isv;
    if (m.matchtime) this.ti = m.matchtime; // time
    if (m.matchtimeextended) this.tie = m.matchtimeextended; // additional time
    if (m.matchtimeextendedExtra) this.tiee = m.matchtimeextendedExtra; // additional time
    if (m.ttype) this.t = m.ttype; // type
    // if (m.matchstatus == "1p" && m.sportId == 1)
    // if (m.t) this.l = m.t == "L"; // islive
    if (m.matchstatus) this.l = this.ms != "not_started";
    if (m.extra_count) this.oc = m.extra_count;
    if (m.hasOwnProperty("oddCount")) {
      this.oc = this.bs == "stopped" ? 0 : m.oddCount;
    }
    if (m.matchstatus) {
      this.ht = isHalfTime(m.matchstatus, m.sportId, m.matchtime);
      this.cls = findClass(m.matchstatus);
    }
    this.isf = false;
    // if (m.p) this.p = m.p;
    // if (m.lom) this.mo = m.lom;
    // if (m.co) this.co = m.co;
    // if (m.to) this.to = m.to;
    if (hasTv) this.tv = true;
    if (m.scr && this.l) {
      this.scr = m.scr;
      this.itie = this.scr.h == this.scr.a;
      this.ith = Boolean(this.scr.h + this.scr.a <= 2);
    }
    if (m.setscores && m.setscores.length && this.l) {
      this.sscr = setArranger(m?.setscores ?? "");
    }
    if (m.stt) this.stt = m.stt;
    if (m.IsStatAvailable) this.istt = m.IsStatAvailable;
    if (detailOdds) this.o = detailOdds.map((d) => new Market(d, this));
    else if (m.odds && m.odds.length)
      this.o = m.odds.map((o) => new Market(o, this)); // odds
    this.tio = order(this);
    this.ds = false;
    this.sd = false;
  }

  async check(m, detailOdds = null) {
    let _ch = false;

    console.log("check", m.matchstatus, m.sportId, m.matchtime);
    this.ht = isHalfTime(m.matchstatus, m.sportId, m.matchtime);
    if (m.matchstatus != this.ms) {
      this.ms = m.matchstatus;
      this.cls = findClass(m.matchstatus);
      this.l = this.ms != "not_started";
      Redux.setStatusChanged(this.id);
    }

    if (m.betstatus != this.bs) {
      this.istt = m.betstatus == "stopped" ? false : this.istt;
      this.oc = m.betstatus == "stopped" ? 0 : this.oc;
      this.bs = m.betstatus;
      _ch = true;
    }

    if (m.matchtime != this.ti) {
      this.ti = m.matchtime;
      _ch = true;
    }

    if (m.matchtimeextended != this.matchtimeextended) {
      this.tie = m.matchtimeextended;
      _ch = true;
    }

    if (m.matchtimeextendedExtra != this.tiee) {
      this.tiee = m.matchtimeextendedExtra;
      _ch = true;
    }

    if (m.ttype != this.t) {
      this.t = m.ttype;
      _ch = true;
    }

    if (m.stt != this.stt) {
      this.stt = m.stt;
      _ch = true;
    }

    if (m.iss != this.iss) {
      this.iss = this.bs == "stopped" ? true : m.iss;
      _ch = true;
    }

    if (m.isv != this.isv) {
      this.isv = this.bs == "stopped" ? false : m.isv;
      _ch = true;
    }

    if (m?.IsStatAvailable != this.istt) {
      this.istt = m?.IsStatAvailable ?? false;
      _ch = true;
    }

    let nt = await order(this);
    if (this.tio != nt) {
      this.tio = nt;
      _ch = true;
    }

    if (this.pl == 90 && this.sid == 1 && m.matchstatus == "4p") {
      this.remove();
      Redux.setStatusChanged(this.id);
      _ch = true;
    }

    if (_ch) {
      Redux.setMid(this.id);
    }
  }

  async update(m, detailOdds = null) {
    let _ch = false;
    this.oc = 0;

    if (m.time.matchtime && m.time.matchtime != this.ti) {
      this.ti = m.time.matchtime;
      _ch = true;
    }

    if (m.time.matchtimeextended && m.time.matchtimeextended != this.tie) {
      this.tie = m.time.matchtimeextended;
      _ch = true;
    }

    if (
      m.time.matchtimeextendedExtra &&
      m.time.matchtimeextendedExtra != this.tiee
    ) {
      this.tiee = m.time.matchtimeextendedExtra;
      _ch = true;
    }

    if (m.hasOwnProperty("betstatus") && m.betstatus != this.bs) {
      this.bs = m.betstatus;
      this.iss = m.betstatus == "stopped" ? true : m.iss;
      this.isv = m.betstatus == "stopped" ? false : m.isv;
      this.istt = m.betstatus == "stopped" ? false : this.istt;
      this.oc = m.betstatus == "stopped" ? 0 : this.oc;
      _ch = true;
    }

    if (m.hasOwnProperty("betstatus") && m.betstatus == "stopped") {
      this.bs = m.betstatus;
      this.iss = true;
      this.isv = false;
      this.istt = false;
      this.oc = 0;
      _ch = true;
    }

    if (m.score.matchscore && convertScore(m.score.matchscore) != this.scr) {
      this.scr = convertScore(m.score.matchscore);
      this.itie = this.scr.h == this.scr.a;
      this.ith = Boolean(this.scr.h + this.scr.a <= 2);
      _ch = true;
    }

    this.ht = isHalfTime(m.matchstatus, this.sid, m.time.matchtime);
    if (m.matchstatus && m.matchstatus != this.ms) {
      this.ms = m.matchstatus;
      this.cls = findClass(m.matchstatus);
      this.l = this.ms != "not_started";

      if (this.ms == "ended") {
        this.remove();
        _ch = false;
      } else {
        _ch = true;
      }

      Redux.setStatusChanged(this.id);
    }

    if (this.pl == 90 && this.sid == 1 && this.ms == "4p") {
      this.remove();
      Redux.setStatusChanged(this.id);
      _ch = false;
    }

    if (m.hasOwnProperty("stt") && m.stt != this.stt) {
      this.stt = m.stt;
      _ch = true;
    }

    if (m.hasOwnProperty("oddsCount") && m.oddsCount != this.oc) {
      this.oc = this.bs == "stopped" ? 0 : m.oddsCount;
      _ch = true;
    }

    if (m.hasOwnProperty("isv") && m.isv != this.isv) {
      this.isv = m.isv;
      _ch = true;
    }

    if (m.hasOwnProperty("iss") && m.iss != this.iss) {
      this.iss = m.iss;
      _ch = true;
    }

    if (m.hasOwnProperty("cards")) {
      if (
        m.cards.hasOwnProperty("redhome") &&
        m.cards.redhome != this.stt?.c?.r?.h
      ) {
        this.stt.c.r.h = m.cards.redhome;
        _ch = true;
      }

      if (
        m.cards.hasOwnProperty("redaway") &&
        m.cards.redaway != this.stt?.c?.r?.a
      ) {
        this.stt.c.r.a = m.cards.redaway;
        _ch = true;
      }
    }

    let nt = await order(this);
    if (this.tio != nt) {
      this.tio = nt;
      _ch = true;
    }

    if (detailOdds) this.o = detailOdds.map((d) => new Market(d, this));
    else if (this.o && this.o.length && m.odds && m.odds.length)
      this.o.forEach((market) => {
        let fMarket = m.odds.find((mo) => mo.oddsid == market.mid);
        if (fMarket) market.update(fMarket, this);
      });

    if (_ch) Redux.setMid(this.id);
  }

  detail(odds) {
    let a = [];

    odds.map((od) => {
      a.push(new Market(od, this));
    });
    this.ds = true;
    this.o = a;

    Redux.setMid(this.id);
  }

  remove() {
    let m = store.getState().live_matches;
    let i = m.findIndex((x) => x.id == this.id);
    if (i != -1) {
      m.splice(i, 1);
    }
  }
}

export default Match;
