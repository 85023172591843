import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import _ from "lodash";
import LiveMatchRow from "./live-match-row";

class MatchList extends React.Component {
  static defaultProps = {
    showTitle: false,
    index: 0,
  };

  constructor(props) {
    super(props);
    this.interval = undefined;
    this.state = {
      sc: props.sc,
      onlyFavorites: props.onlyFavorites,
      schema: props.schema,
      relist: false,
    };
  }

  componentDidMount() {
    this._set();
    setTimeout(() => {
      this.props.onRef(this.list);
    }, 1000);
  }

  _set() {
    this.interval = setInterval(() => {
      this.setState({ relist: !this.state.relist });
    }, 50000);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps?.sc !== this.state.sc) {
      this.setState({ sc: nextProps.sc });
      return true;
    }

    if (nextProps?.schema !== this.state.schema) {
      this.setState({ schema: nextProps.schema });
      return true;
    }

    if (nextProps?.onlyFavorites !== this.state.onlyFavorites) {
      this.setState({ onlyFavorites: nextProps.onlyFavorites });
      return true;
    }

    if (nextState.relist !== this.state.relist) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  render() {
    const { live_matches, schema, z, l, orderBy, onlyFavorites, halfTime } =
      this.props;

    const row_height = 58;
    const row_height_fh = 116;

    let matches = live_matches;

    // Separate live matches and not-live matches
    const liveMatches = matches.filter((match) => match.is_live === true);
    const notLiveMatches = matches.filter((match) => match.is_live !== true);

    // Sort live matches by matchtime (descending) and then by created_at (ascending)
    const sortedLiveMatches = _.orderBy(
      liveMatches,
      ["matchtime", "created_at"],
      ["desc", "asc"]
    );

    // Sort not-live matches by matchtime (descending) and then by created_at (ascending)
    const sortedNotLiveMatches = _.orderBy(
      notLiveMatches,
      ["matchtime", "created_at"],
      ["desc", "asc"]
    );

    // Combine live matches and not-live matches
    const sortedMatches = [...sortedLiveMatches, ...sortedNotLiveMatches];

    if (onlyFavorites) {
      // Filter only favorite matches
      matches = sortedMatches.filter((x) => x.is_favorite === true);
    } else {
      matches = sortedMatches;
    }

    return (
      <>
        {matches.map((item, index) => {
          return (
            <LiveMatchRow
              key={item.id}
              z={z}
              l={l}
              schema={schema}
              item={item}
              oe={index % 2 === 0 ? "odd" : "even"}
              halfTime={halfTime}
              onShowStatistics={this.props.onShowStatistics}
              onAddFavorite={this.props.onAddFavorite}
              onDetail={this.props.onDetail}
            />
          );
        })}
      </>
    );
  }
}

const msp = (state) => {
  return {
    live_matches: state.live_matches,
    sc: state._statusChanged,
  };
};

export default connect(msp)(injectIntl(MatchList));
